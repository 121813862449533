@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    html{
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
}

.alert-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.alert-content {
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 300px;
}

.alert-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.alert-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

#alertOkButton {
    background-color: #007bff;
    color: white;
}

#alertContactButton {
    background-color: #28a745;
    color: white;
}
  